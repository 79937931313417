import React from "react"
import Section from "../../Elements/Section"
import ClinicInformation from "../ClinicInformation"

const ClinicsInformation = ({ values }) => (
  <Section title="Clinic Information" id="clinic-information">
    <h5 className="mt-2">Primary Office</h5>
    <ClinicInformation
      type="primary"
      values={values?.primaryClinic}
      isRequired
    />
    <h5 className="mt-2 has-text-weight-normal">
      Secondary Office{" "}
      <span className="has-text-grey is-italic">(Optional)</span>
    </h5>
    <ClinicInformation type="secondary" values={values?.secondaryClinic} />
  </Section>
)

export default ClinicsInformation
