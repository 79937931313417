import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormSelect from "../Elements/Form/FormSelect"
import Address from "../PersonalDetails/Address"

import {
  handleLoadHospitalOptions,
  handleOnHospitalChange,
} from "./services/handleSelectHospitals"

import hospitals from "./utils/hospitals.json"

/**
 * Parameters:
 *
 * props: {
 *          'type': String,
 *          'values': Object,
 *          'isRequired': Boolean
 *        }
 */
const ClinicInformation = ({ type, values, isRequired }) => {
  const clinic = `${type}Clinic`
  const addressFieldNames = {
    streetAddress: `${clinic}.streetAddress`,
    city: `${clinic}.city`,
    province: `${clinic}.province`,
  }

  return (
    <Fragment>
      <FormSelect
        name={`${clinic}.name`}
        options={hospitals}
        value={values?.name}
        helper="Please select 'Other' if you can't find your hospital/clinic in the list."
        label="Hospital/Clinic"
        isRequired={isRequired}
        placeholder="Philippine General Hospital"
        loadOptions={handleLoadHospitalOptions}
        onChange={handleOnHospitalChange(`${clinic}.name`)}
        defaultOptions
        isAsync
        hideOptional={!isRequired}
      />

      {values?.name.value === "Other" && (
        <FormInput
          name={`${clinic}.otherHospital`}
          label="Please indicate your hospital/clinic"
          placeholder="Philippine General Hospital"
          isRequired={isRequired}
          hideOptional={!isRequired}
        />
      )}

      <FormInput
        name={`${clinic}.clinicHours`}
        label="Clinic Hours"
        isRequired={isRequired}
        placeholder="MWF 8am-12nn, TTh 1-3pm"
        hideOptional={!isRequired}
      />
      <FormInput
        name={`${clinic}.clinicContactNumber`}
        label="Clinic Contact Number"
        type="tel"
        maxLength={11}
        placeholder="88881234"
        isRequired={isRequired}
        hideOptional={!isRequired}
        isNumeric
      />

      <Address
        fieldNames={addressFieldNames}
        values={values}
        isRequired={isRequired}
        isNationwide
      />
    </Fragment>
  )
}

export default ClinicInformation
