import React from "react"

import Section from "../../Elements/Section"
import FormInput from "../../Elements/Form/FormInput"
import FormSelect from "../../Elements/Form/FormSelect"
import PersonalInformation from "../PersonalInformation"

import { specializationChoices } from "../utils/formData"

const DoctorPersonalInformation = ({ values, handleSpecializationChange }) => (
  <Section title="Personal Information" id="personal-information">
    <PersonalInformation />
    <FormInput
      name="prcLicenseNumber"
      label="PRC License Number"
      isRequired
      placeholder="0012345"
      maxLength={7}
      isNumeric
    />
    <FormSelect
      value={values.specialization}
      label="Specialization"
      name="specialization"
      isRequired
      options={specializationChoices}
      onChange={handleSpecializationChange}
      placeholder="Select or type specialization"
    />
    {values?.specialization?.value === "Other" && (
      <FormInput
        name="otherSpecialization"
        label="Please indicate your specialization"
        isRequired
        placeholder="Urology"
      />
    )}
  </Section>
)

export default DoctorPersonalInformation
