import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import DoctorEnrollment from "../../components/Enrollment/DoctorEnrollment"

export default () => (
  <Layout
    title="Register as Partner Doctor"
    subtitle={
      <span>
        Please answer the following questions accurately.
        <br />
        Your information will be used as a basis for your enrollment.
      </span>
    }
    seoTitle="Register as Partner Doctor"
    display={{ footer: false, helpCenterBanner: false }}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <DoctorEnrollment />
    </Container>
  </Layout>
)
